.catalog {
    $el: &;

    &--container {
        height: auto;
        flex-shrink: 0;
        width: 100%;
        padding: 16px 24px;

        display: grid;
        grid-template-columns: repeat(auto-fit, 100%);
        justify-content: space-evenly;
        align-items: flex-start;
        row-gap: 16px;
        column-gap: 16px;

        @include breakpoints(xxxsm){
            grid-template-columns: repeat(auto-fit, calc(50% - 16px));
        }

        @include breakpoints(xxsm){
            grid-template-columns: repeat(auto-fit, 200px);
        }

        @include breakpoints(sm) {
            grid-template-columns: repeat(auto-fit, 150px);
        }

        @include breakpoints(md) {
            grid-template-columns: repeat(auto-fit, calc(((((100vw - 100px) / 3) - 40px) / 2) - 16px));
        }

        @include breakpoints(xxlg) {
            grid-template-columns: repeat(auto-fit, 200px);
        }

        @include breakpoints(md) {
            &::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 8px;
                margin-left: 8px;
                background-color: #f2f2f2;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-clip: content-box;
                background-color: var(--scrollbar-color);
                -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
            }
        }

        .override_card_cover_catalog {
            align-items: center;
            justify-content: center;
            display: flex;
        }

        &.catalog--outfit .override_card_cover_catalog {
            width: 100%;
            min-height: 0;
        }
    }

    &--container--with-remove {
        padding: 8px 24px 16px 24px;
    }

    &--empty {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        text-align: center;
    }

    &--item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    &--item-favorites {
        @extend .catalog--item;
        
        height: 100%;
    }

    &--horizontal--container {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        flex-direction: row;
        overflow-x: auto;
        scrollbar-width: none;
        min-height: 134px;
        height: 100%;
        gap: 4px;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &--remove-text {
        text-align: center;
        font-size: 16;
        font-weight: bold;
    }

    &--horizontal--remove-text {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 35%;
        font-size: 12px;
        font-weight: bold;
    }

    &--favorites--container {
        height: auto;
        width: 100%;
        padding: 16px 8px;

        display: grid;
        grid-template-columns: repeat(auto-fit, 100%);
        align-items: flex-start;
        row-gap: 16px;
        column-gap: 16px;

        @include breakpoints(xxxsm){
            grid-template-columns: repeat(auto-fit, calc(50% - 8px));
        }

        @include breakpoints(sm) {
            grid-template-columns: repeat(auto-fit, calc(33% - 16px));
            padding: 16px 24px;
        }

        @include breakpoints(md) {
            grid-template-columns: repeat(auto-fit, calc(20% - 16px));
        }

        @include breakpoints(xxlg) {
            grid-template-columns: repeat(auto-fit, 200px);
        }
    }
}