.layout {
    $el: &;

    $header-height: 40px;
    $header-height-mobile: 34px;
    $sub-header-height: 34px;

    &--content {
        display: flex;
        flex-direction: column-reverse;
        flex: 1;
        height: 100%;
        overflow-y: auto;

        @include ratio_breakpoints(one) {
            flex-direction: row;
        }
    }

    &--container {
        background-color: $lightcolor;
        height: auto;
        flex: 1;
        width: 100%;
    }

    &--drawer-desktop {
        display: flex;
        position: relative;
        transition: flex .3s cubic-bezier(.7, .3, .1, 1), max-width .3s cubic-bezier(.7, .3, .1, 1);
        overflow: hidden;
        background-color: white;
    }

    &--header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        z-index: 2;
        height: auto;
        width: 100%;
        padding: 0px;
        line-height: initial;
        border-bottom: 1px solid $lightcolor;

        .layout--page-swipe &,
        .layout--page-product &,
        .layout--page-model & {
            border-bottom: none;

            @include ratio_breakpoints(one) {
                border-bottom: 1px solid $lightcolor;
            }
        }
    }

    &--header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;

        >:first-child {
            margin-left: 16px;
        }

        >:nth-child(n) {
            margin-right: 16px;
        }
    }

    &--header-title {
        display: none;
        align-items: center;

        @include ratio_breakpoints(one) {
            display: flex;
        }

        &-separator {
            border-left: 1px solid $lightcolor;
        }
    }

    &--title-mobile {
        display: none;
        position: absolute;
        width: 100%;
        padding: 0 56px;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &--header-main-container {
        flex: 1;
        overflow-x: hidden;
    }

    &--header-top-container {
        display: flex;
        flex-direction: column-reverse;

        @include ratio_breakpoints(one) {
            flex-direction: row;
        }
    }

    &--header-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        min-height: $header-height-mobile;

        border-bottom: 1px solid $lightcolor;

        overflow-x: auto;
        position: relative;

        @include ratio_breakpoints(one) {
            border-bottom: none;
            overflow-x: unset;
        }
    }

    &--header-button-container {
        display: flex;
        flex: 5;
        justify-content: flex-end;
        gap: 16px;
        padding-right: 16px;
    }

    &--header-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: $header-height-mobile;
        height: 100%;
        width: 100%;
        overflow-x: hidden;

        .layout--page-model &,
        .layout--page-swipe &,
        .layout--page-product & {
            display: none;

            @include ratio_breakpoints(one) {
                display: flex;
            }
        }

        @include ratio_breakpoints(one) {
            min-height: $header-height;
        }
    }

    &--header-left-content {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        overflow-x: hidden;
    }

    &--header-left-content-menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        height: $header-height-mobile;
        justify-content: space-between;
        min-width: 0;

        @include ratio_breakpoints(one) {
            height: $header-height;
            justify-content: flex-start;
        }
    }

    &--header-burger-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        padding: 0;
        margin-left: 16px;
        margin-right: 8px;

        &>.anticon {
            font-size: 20px;
        }
    }

    &--swipe-content {
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        height: 100%;
        transition: background 1s;

        @include ratio_breakpoints(one) {

            .layout--title {
                display: none;
            }
        }
    }

    &--swipe-look-container {
        position: absolute;
        height: 100%;
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-image-img {
            width: auto;
            height: 100%;
        }
        img {
            width: auto;
            height: 100%;
            object-fit: cover;
        }
    }

    &--left-part {
        flex: 0.2;
        display: flex;
        justify-content: flex-start;
        background-color: white;
        border-right: none;
        border-top: 1px solid lightgray;
        transition: flex .3s cubic-bezier(.7, .3, .1, 1);

        min-height: 185px;
        min-width: 100px;

        @include ratio_breakpoints(one) {
            border-right: 1px solid lightgray;
            border-top: none;
            max-width: 100px;
        }
    }

    &--right-content {
        flex: 1;
        display: none;
        background-color: $lightcolor--lighter;
        transition: flex 0.3s cubic-bezier(.7, .3, .1, 1);

        @include ratio_breakpoints(one) {
            display: flex;
            overflow-y: auto;

            .layout--title {
                display: none;
            }
        }
    }

    &--center-content {
        padding: 0;
        background-color: white;
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: auto;
        overflow-x: hidden;
        transition: flex 0.3s cubic-bezier(.7, .3, .1, 1), margin 0.3s cubic-bezier(.7, .3, .1, 1);
    }

    &--main-overlay {
        @extend .layout--center-content;

        position: relative;
        padding: 0 !important;
    }

    &--side-left {
        background-color: #f7f7fa;
    }

    &--side-right {
        background-color: $background;
    }

    &--title {
        display: none;

        @include ratio_breakpoints(one) {
            display: flex;
        }

        min-height: 0 !important;
    }

    &--headroom {
        background-color: #fff;
        padding: 16px 16px 0;

        @include ratio_breakpoints(one) {
            display: none;
        }

        .sticky {
            top: 65px !important;
            z-index: 1;
        }
    }

    &--poweredby-mobile {
        @include ratio_breakpoints(one) {
            display: none;
        }
    }

    %powered-by {
        color: rgba(0, 0, 0, .25);
        text-align: center;
        margin-top: 32px;
        padding-bottom: 8px;
        font-size: 12px;

        a {
            text-decoration: underline;
            color: rgba(0, 0, 0, .25);
        }
    }

    &--poweredby-mobile {
        @extend %powered-by;

        .layout--page-model & {
            margin-top: 0;
        }

        @include ratio_breakpoints(one) {
            display: none;
        }
    }

    &--poweredby-desktop {
        @extend %powered-by;
        display: none;

        @include ratio_breakpoints(one) {
            display: block;
        }
    }

    &--toggleview {
        position: absolute;
        right: 0;
        top: 16px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        background-color: white;
        z-index: 1000;
    }
}