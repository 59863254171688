.drawer {

    &--catalog {

        // ---- MIN-WIDTH SYNC WITH CATALOG CONTAINER GRID TEMPLATE BREAKPOINT ----
        @include breakpoints(xxxsm){
            min-width: calc((50% - 16px) * 2 + 48px);
        }

        @include breakpoints(xxsm){
            min-width: calc(200px * 2 + 48px);
        }

        @include ratio_breakpoints(one) {
            min-width: calc(150px + 48px);
        }

        @include breakpoints(md) {
            min-width: calc((((((100vw - 100px) / 3) - 40px) / 2) - 16px) * 2 + 48px);
        }

        @include breakpoints(xxlg) {
            min-width: calc(200px * 2 + 48px);
        }

        & .ant-drawer-title {
            width: 100%;
        }
        
        & .ant-drawer-body {
            padding: 0;
            background: white;
        }

        &-remove {
            & .ant-drawer-body {
                margin-top: 56px;
            }  
        }
        
        & .ant-drawer-header {
            padding: 0;
            border-bottom: 1px solid $lightcolor;
        
            @include ratio_breakpoints(one) {
                border: unset;
                border-bottom: 1px solid $lightcolor;
            }
        }
        
        & .ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper,
        & .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper,
        &.ant-drawer-left>.ant-drawer-content-wrapper,
        &.ant-drawer-bottom>.ant-drawer-content-wrapper {
            box-shadow: none;
            background: transparent;
        }

        // ---- Need 2px width because 1px is hidden by the div under ----
        &.ant-drawer-left>.ant-drawer-content-wrapper {
            border-right: 1px solid lightgray;
            width: 100%;
        }
    }

    &--content {
        background-color: white;
        border-right: none;
        height: 100%;
        scroll-padding-top: 58px;

        @include ratio_breakpoints(one) {
            display: block;
            overflow-y: auto;
        }
    }

    &--header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: white;
    }

    &--header-nosubheader {
        justify-content: flex-end;
    }

    &--header-right {
        min-width: 72px;
        align-items: center;

        .drawer--header-nosubheader & {
            border-left: none;
            min-width: 32px;
        }
    }

    &--toggle-container {
        position: absolute;
        top: -24px;
        height: 24px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 32px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: white;
        box-shadow: 0 -7px 7px 0 rgba(0, 0, 0, 0.08), 0 -3px 6px -4px rgba(0, 0, 0, 0.12), 0 -9px 28px 8px rgba(0, 0, 0, 0.05);
        z-index: -1;

        .drawer--header-nosubheader & {
            top: 0;
        }

        .ant-btn-default {
            box-shadow: none;
        }

        @include ratio_breakpoints(one) {
            display: flex;
            flex-direction: column;
            top: calc(50% - 66px);
            width: 20px;
            height: 132px;
            right: 1px;
            z-index: 2;
            box-shadow: none;

            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    &--cart {
        & .ant-drawer-header-title {
            width: 100%;
            justify-content: flex-end;
            > .ant-drawer-close {
                margin-inline-end: 0;
                padding: 0;
            }
        }
        
        & .ant-drawer-body {
            padding: 0;
            background: white;
        }
        
        & .ant-drawer-header {
            padding: 7px 7px 0px 7px;
            border: 1px none;
        }
        
        & .ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper,
        & .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper,
        & .ant-drawer-left>.ant-drawer-content-wrapper,
        & .ant-drawer-bottom>.ant-drawer-content-wrapper {
            box-shadow: none;
        }
    }

    &--remove-container {
        position: absolute;
        top: 35px;
        z-index: 2;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        padding: 8px;
    }

    &--search-container {
        padding: 16px;
        position: sticky;
        top: 0;
        z-index: 1;
    }
}