.carousel-swipe {
    $el: &;
    background-color: transparent;

    .slick-list,
    .slick-track {
        height: 100%;
        padding: 0px 0px;
    }

    .slick-track {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .slick-list {
        padding: 11px 16px;
    }

    // Handle Size of slides
    .slick-slide {
        opacity: 1;
        height: auto;

        &>div {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &--arrow {
        position: absolute;
        height: 2rem;
        width: 2rem;
        background: $background !important;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
        display: none;

        @include ratio_breakpoints(one) {
            display: flex;
        }

        svg {
            font-size: 13px;
            color: var(--primary-color);
        }

        &:before {
            display: none;
        }
    }

    &--left {
        left: 4px;
        z-index: 999;

        @include ratio_breakpoints(one) {
            left: 5px;
        }
    }

    &--right {
        right: 4px;
        z-index: 999;

        @include ratio_breakpoints(one) {
            right: 5px;
        }
    }
}