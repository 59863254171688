.cart {
    $el: &;

    &--container {
        display: flex;
        min-height: 100%;
        height: 100%;
        overflow-y: auto;
        width: 100%;
        padding: 16px 16px 0;
        justify-content: space-between;
        flex-direction: column;
    }

    &--buy-look-container {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        padding: 16px 16px 0px 16px;
        z-index: 1;
        background-color: $lightcolor--lighter;
    }

    &--container-with-buy-look {
        @extend .cart--container;

        min-height: unset;
        height: unset;
        margin-top: 94px;
    }

    &--empty {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
    }

    &--space {
        height: 32px;
        width: 100%;
    }

    &--item {
        padding-top: 0px;

        @include ratio_breakpoints(one) {
            padding-top: 0px;
        }
    }

    &--button {
        display: flex;
        flex-direction: column;

        @include ratio_breakpoints(one) {
            flex-direction: row;
        }

        button,
        a {
            min-height: 48px;
            margin-bottom: 12px;

            @include ratio_breakpoints(one) {
                margin-bottom: 0;
            }

            &:first-child {
                margin-right: 20px;
            }
        }
    }

    &--total {
        display: none;
        flex-direction: column;
        padding-left: 0%;

        @include breakpoints(mdl) {
            display: flex;
            padding-left: 50%;
        }
    }

    &--text {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: flex-end;
        align-items: flex-end;
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 0 8px;

        @include ratio_breakpoints(one) {
            flex-direction: row;
            justify-content: flex-end;
            align-content: center;
            align-items: flex-end;
        }

        &>div {
            @include ratio_breakpoints(one) {
                margin-right: 42px;
            }
        }

        h1 {
            margin-top: 0 !important;
            margin-bottom: 0;
        }
    }
}