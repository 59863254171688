.select {
  $el: &;

  .ant-select-selector,
  .ant-select-selection-placeholder,
  ant-select-item ant-select-item-option,
  .ant-select-selection-item {
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 24px;
    color: #1c1c1c;
  }

  .ant-select-arrow {
    color: #1c1c1c;
    svg {
      color: #1c1c1c;
    }
  }

  &--container {
    width: 100%;
    background: $background;

    .ant-select-selector {
      box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    }

    .ant-select-selection-item {
        display: flex;
        justify-content: space-between;
        height: 100%;
    }
  }

  &--text {
    @extend %text-base;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  &--option .ant-select-item-option-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  &--option-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  &--option-image {
    max-height: 75px;
    height: 100%;
  }

  &--option-svg {
    height: 40px;
    max-height: 40px;
    width: 40px;
    stroke-width: 1px;
    fill: transparent;
    stroke: black;
  }

  &--option-text-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
  }

  &--option-extra {
    padding-left: 8px;
    color: gray;
    display: block;
    overflow: hidden;
  }

  &--multiselect-container {
    flex-basis: calc(50% - 8px);
  }

  &--multiselect-label {
    text-transform: capitalize;
  }

  &--multiselect--filter-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
    width: 100%;

    &>.ant-btn {
        padding: 0;
    }
  }
}

.ant-layout-content {
  position: relative;
}