.product {
  $el: &;
  position: relative;
  height: 100%;

  &--container {
    padding: 16px;
    height: 100%;
    justify-content: center;
    gap: 16px;
    overflow-y: auto;

    @include breakpoints(sm) {
        padding: 16px 24px;
    }
  }

  &--container-with-wishlist {
    padding: 16px 24px;
  }

  &--bottom {
    display: flex;
    min-height: 100px;
    height: 100px;
    width: 100%;
  }

  &--brand {
    @extend %title-h2;
    font-weight: normal !important;
  }

  &--title {
    @extend %title-h2;
  }

  &--price {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 16px;
  }

  &--price--promotion {
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 0;
  }

  &--price--original {
    @extend .product--price;

    text-decoration: line-through;
  }

  &--price--percent {
    @extend .product--price;

    margin-left: 12px;
  }

  &--price--final {
    @extend .product--price;
  }

  &--text {
    @extend %text-base;
    font-size: 13px;

    @include ratio_breakpoints(one) {
      max-width: 80%;
    }
  }

  &--bottom-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 8px;
    margin-bottom: 16px;

    .ant-select {
        width: 247px;
    }

    .button--add-to-cart, a {
        width: 247px;
    }
  }

  &--bottom-container-multiselect {
    @extend .product--bottom-container;

    .ant-select {
        min-width: 247px;
        width: 100%;
    }

    .button--add-to-cart, a {
        min-width: 247px;
        width: calc(50% - 8px);
    }

    @include ratio_breakpoints(one) {
        max-width: 80%;
    }
  }

  &--list {
    margin-bottom: 20px;
    padding: 0;
    padding-left: 20px;

    li {
      @extend %text-base;
      font-size: 13px;
      line-height: 18px;
    }
  }

  &--left-side {
    min-width: 20%;
    @include breakpoints(sm) {
      max-width: 50%;
    }
  }

  &--right-side {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &--extra-product {
    padding: 0;
    margin-bottom: 16px;
    width: fit-content;
  }

  &--subname {
    color: gray;
  }
}
