.card-carousel {
    $el: &;

    &--container {
        border: none;
        cursor: default;
        box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);

        display: flex;
        flex-direction: column;
        height: 100%;
        margin-right: auto;
        margin-left: auto;

        .ant-card-body {
            &::after, &::before {
                display: none;
            }

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 145px;
            height: 145px;
        }

        .ant-card-cover {
            height: calc(100% - 145px);
            flex: 7;
            margin: 0;

            &>.ant-image {
                height: 100%;

                &>img {
                    object-fit: cover;
                    height: 100%;
                }
            }
        }

        &--horizontal {
            flex-direction: row;

            .ant-card-body {
                flex: 1;
                width: 40%;
                height: unset;
            }

            .ant-card-cover {
                width: 60%;
                height: 100%;
                flex: unset;
            }
        }
    }

    &--body-container {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &--image {
        object-fit: cover;
        margin-bottom: 8px;
        width: 100%;
        height: 100%;
        border-radius: 16px 16px 0 0;
    }

    &--title {
        @extend %title-base;

        font-size: 20px !important;
        line-height: 25px !important;
        margin-bottom: 8px;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &--text {
        font-weight: 500 !important;
        text-align: left;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 12px;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &--button {
        min-height: 33px;
        width: auto;
        max-width: 100%;
        color: $background;
        border: none;
    }

    &--hide {
        display: none;
    }

    &--swipe {
        justify-content: center;
        align-items: center;
        display: flex;
        height: 180px;
        background-color: transparent;
        border: none;
        margin: 0;
    }

    &--swipe-clone {
        opacity: 0.2;
    }

    &--swipe-image {
        object-fit: contain;
        margin-bottom: 10px;
        width: 100%;
        height: 100%;
        position: absolute;
        pointer-events: none;

        &.swipe-format {
            object-fit: cover;
            margin-bottom: 0;
            width: auto;
        }
    }
}

%title-base {
    font-weight: bold !important;

    ::first-letter {
        text-transform: uppercase;
    }
}