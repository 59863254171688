.style-bar {

    &--container {
        display: flex;
        align-items: center;
        padding: 8px;
        gap: 16px;
        flex-direction: row;
        height: 100%;

        overflow-x: auto;

        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        @include ratio_breakpoints(one) {
            flex-direction: column;
            overflow-y: auto;
            overflow-x: unset;
            width: 100px;
        }
    }

    &--element {
        cursor: pointer;
        display: flex;
        flex: none;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        width: 100px;
        position: relative;
        overflow: hidden;

        @include ratio_breakpoints(one) {
            width: 100%;
            height: unset;
        }

        &:nth-of-type(1) {
            margin-left: auto;
        }

        &:nth-last-of-type(1) {
            margin-right: auto;
        }
    }

    &--title {
        background-color: #fff9;
        width: 100%;
        text-align: center;
        font-size: 12px;
        line-height: 15px;
        text-overflow: ellipsis;
        text-decoration: underline;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 15%;

        @include ratio_breakpoints(one) {
            height: unset;
            font-size: 11px;
            line-height: normal;
        }
    }

    &--arrow-container {
        position: absolute;
        cursor: pointer;
        color: var(--primary-color);
        z-index: 2;
        background-color: $lightcolor--lighter;
        height: 25px;
        width: 25px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
    }

    &--arrow-container-left {
        @extend .style-bar--arrow-container;

        left: 8px;
    }

    &--arrow-container-right {
        @extend .style-bar--arrow-container;

        right: 8px;
    }

    &--arrow-container-up {
        @extend .style-bar--arrow-container;

        top: 8px;
    }
    &--arrow-container-down {
        @extend .style-bar--arrow-container;

        bottom: 8px;
    }

    &--remove-container {
        z-index: 1;
        position: absolute;
        right: 8px;
        top: 8px;

        width: 40%;

        @include ratio_breakpoints(one) {
            right: 0;
            top: 0;
        }

        .ant-btn,
        .ant-btn-icon {
            width: 100% !important;
            height: 100% !important;
        }

        .anticon {
            font-size: 28px !important;
            color: gray;
            border-radius: 50%;
            width: 100%;
            height: 100%;

            @include ratio_breakpoints(one) {
                font-size: 24px !important;
            }

            >svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    &--plus-container {
        @extend .style-bar--remove-container;

        top: unset;
        right: unset;
    }

    &--checkbox {
        .ant-checkbox {
            padding: 3px 2px 8px 8px;
            box-shadow: none;
        }

        .ant-checkbox-checked:after {
            border: none;
        }

        .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled,
        .ant-checkbox-disabled .ant-checkbox-input {
            cursor: pointer !important;
        }
    }

    &--subheader {
        min-width: 0;
        width: 100%;
    }

    &--subheader-container {
        background-color: $lightcolor--lighter
    }

    &--filter-icon {
        width: 30px;
        height: 30px;
        font-size: 30px;
        line-height: 30px;
        margin-right: -4px;
        padding: 0;

        &>.anticon {
            font-size: 30px;
        }
    }

    &--filter-container {
        @extend .button--filter;

        margin: 0;
        padding-right: 16px;
        padding-left: 8px;
        background-color: $lightcolor--lighter;

    }

    &--image-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 0;
        width: 100%;
        height: 65%;

        @include ratio_breakpoints(one) {
            height: unset;
            padding: 0 8px;
        }
    }

    &--image-container-zoom {
        padding: 0;
    }



    &--root-image {
        height: 100%;
        width: 100%;  
        overflow: hidden;

        @include ratio_breakpoints(one) {
            height: 80px;
        }
    }

    &--image {
        object-fit: contain;

        &.ant-image-img {
            height: 100%;
        }
    }
    
    &--image-zoom {
        @extend .style-bar--image;

        transform: scale(2) translate(0, 25%);
    }

    &--chose-model-first {
        font-weight: bold;
    }
}