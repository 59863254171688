/**
 * Main colors
 */
$primary: #3e7bfa;
$primary--lighter: #6698fa;
$primary--darker: #3568d4;

/**
 * Light and Dark colors
 */
$lightcolor: #ebebf0;
$lightcolor--lighter: #fafafc;
$lightcolor--darker: #f2f2f5;

$darkcolor: #28293d;
$darkcolor--lighter: #8f90a6;
$darkcolor--darker: #555770;

/**
 * Background
 */
$background: #ffffff;

/**
 * Contextual colors
 */
$errorcolor: #ff5c5c;
$errorcolor--lighter: #ff8080;
$errorcolor--darker: #ff3b3b;

$successcolor: #39d98a;
$successcolor--lighter: #57eba1;
$successcolor--darker: #06c270;
