.card-item-horizontal {
    &--image {
        object-fit: contain;
        height: 100%;

        &.ant-image-img {
            height: 100%;
        }
    }

    &--container {
        height: 100%;
        width: 100px;
        min-width: 100px;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 0px;
        padding: 4px;
        transition: outline 0.2s, background-color 0.2s;

        .ant-image {
            height: 65%;
        }
    }

    &--container-selected {
        outline: 1px solid;
    }

    &--container-with-name {
        @extend .card-item-horizontal--container;

        width: 120px;
        min-width: 120px;
    }

    &--container-empty {
        justify-content: center;
    }

    &--body {
        display: flex;
        height: 35%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &--promotion-container {
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 8px;
    }

    &--price--percent,
    &--price--original {
        font-size: 10px;
    }

    &--price--original {
        text-decoration: line-through;
    }

    &--price--current {
        font-size: 12px;
        text-align: center;
    }

    &--button-color {
        position: absolute;
        left: 0;
        bottom: calc(35% - 10px);
        z-index: 1;
        background-color: transparent;
        opacity: 0.4;
    }

    &--price-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        
        .card-item-horizontal--container-with-name & {
            height: 50%;
        }
    }

    &--product-name {
        font-size: 10px;
        width: 100%;
        margin: 0px !important;
        text-align: center;
    }
}