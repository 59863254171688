.icon {
  $el: &;

  &--medium {
    svg {
      height: 24px;
      width: 24px;
    }
  }

  &--small {
    svg {
      height: 14px;
      width: 14px;
    }
  }
}
