.colorpicker {
    &--container {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &--color-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 16px;
        min-height: 25px;
        align-items: center;
        padding-left: 5px;
    }

    &--color {
        width: 17px;
        height: 17px;
        border-radius: 10px;
        cursor: pointer;

        @media (hover: hover) {
            &:hover {
                transform: scale(1.2);
            }
        }
    }

    &--color-outline {
        width: 25px;
        height: 25px;
        border-radius: 13px;
        position: absolute;
        left: -4px;
        top: -4px;
        border: 1px solid black;
    }
}