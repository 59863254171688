.button {
    $el: &;

    &--mobile {
        @include ratio_breakpoints(one) {
            display: none !important;
        }
    }

    &--desktop {
        @include mx_ratio_breakpoints(one) {
            display: none !important;
        }
    }

    &--primary {
        font-weight: 600;
    }

    &--modal {
        flex: 1;
        min-width: 180px;
    }

    &--outlined {
        border: 1px solid;
        padding: 8px 12px 8px 8px;
    }

    &--header {
        border: none;
        box-shadow: none;
        font-weight: bold;
    }

    &--underlined {
        display: inline-block;

        >span{
            text-decoration: underline;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &--icon {
        padding: 0 !important;
        margin: 0 !important;
        border: none;
        box-shadow: none;
    }

    &--icon:after {
        display: none !important;
        opacity: 0 !important;
    }

    &--icon-left {
        @extend .button--icon;

        >span {
            justify-content: flex-start !important;
        }
    }

    &--icon-right {
        @extend .button--icon;

        >span {
            justify-content: flex-end !important;
        }
    }

    &--stylebar-icon {
        @extend .button--icon;
        background-color: transparent;
    }

    &--exit-experience {
        @extend .button--icon;

        margin-left: 16px !important;
        margin-right: 8px !important;

        >span {
            font-size: 20px;
        }

        @include ratio_breakpoints(one) {
            >span {
                font-size: 24px;
            }
        }
    }

    &--text {
        @extend %text-vsmall;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        padding: 0;
        padding-right: 40px;

        .anticon-left {
            margin-right: 8px;
        }
    }

    &--space-r {
        margin-right: 8px;
    }

    &--third {
        width: 100%;
        overflow: hidden;

        @include ratio_breakpoints(one) {
            width: auto;
            min-width: 125px;
        }
    }

    >span {
        display: table;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &--clear {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
        color: grey;
        cursor: pointer;
        border: none;
        background: transparent !important;
        box-shadow: none;
    }

    &--main {
        border: none;
    }

    &--card-main {
        width: 100%;
        border: none;

        &>span {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
        }
    }

    &--filter {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;

        .ant-badge-count {
            line-height: 13px;
            font-size: 10px;
            border: 1px solid black;
            color: black;
        }
    }

    &--detail {
        @extend .button--card-main;

        margin-top: 8px;
    }

    &--switch-view {
        &-catalog {
            @extend %switch-view-default;

            .layout--page-catalog & {
                color: black;
            }
        }

        &-swipe {
            @extend %switch-view-default;

            .layout--page-swipe & {
                color: black;
            }
        }

    }

    &--favorite {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        padding: 0px;

        > .anticon {
            font-size: 20px;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &--favorite:after {
        display: none;
    }

    &--shop {
        font-weight: bold;
        font-size: 14px;

        @include ratio_breakpoints(one) {
            font-size: 14px;
        }
    }

    &--toggle-size {
        border: none;
        color: gray;

        &.button--toggle-size-disabled {
            opacity: 0.3;
            cursor: not-allowed;
        }

        &.ant-btn-icon-only {
            width: 50px;
            height: 100%;
            padding: 0;

            @include ratio_breakpoints(one) {
                width: 100%;
                height: 50px;

                span {
                    height: 100%;
                }

                svg {
                    height: 50px;
                    width: unset;
                }
            }

            span {
                width: 100%;
            }

            svg {
                width: 100%;
            }
        }
    }

    &--remove {
        min-width: 50%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
        color: black;
        border-color: black !important;
        background-color: white !important;

        &:hover, &:active {
            color: black !important;
        }
    }

    &--buy-look {
        width: 100%;
        border: none;
    }

    &--full-window {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .ant-btn-icon {
            padding: 6px;
        }

        &>span:not([class]) {
            padding-bottom: 4px;
        }
    }
}

%switch-view-default {
    color: lightgray;

    :hover {
        transition: color 0.3s;
        color: black;
    }
}

.ant-btn[ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
}