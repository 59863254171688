.card-model {
    $el: &;

    &--container {
        border: none;
        overflow: hidden;

        .ant-card-body {
            padding: 0;
        }

        &:hover {
            .card--image {
                filter: blur(3px);
                -webkit-filter: blur(3px);
            }
        }
    }

    &--image {
        width: 100%;
        height: auto;
    }

    &--image-container {
        position: relative;
    }

    &--background {
        position: relative;
        width: 100%;
        /* la largeur est definie par le parent */
        height: 0;
        padding-bottom: 125%;
        /* calcul de la hauteur en fonction de la largeur du parent */
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    &--top {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &--top-first-line {
        display: flex;
    }

    &--bottom-left {
        position: absolute;
        bottom: 8px;
        left: 8px;
        display: flex;
        flex-direction: column;
        z-index: 10;

        svg {
            width: 25px;
            height: 25px;
        }
    }

    &--bottom-left-blur {
        backdrop-filter: blur(10px);
        padding: 8px;
        bottom: 0;
        left: 0;
    }

    &--tooltip {
        padding: 0;

        a {
            text-decoration: underline;
        }
    }

    &--image {
        width: 100%;
        height: auto;
    }

    &--image-container {
        position: relative;
    }

    &--background {
        position: relative;
        width: 100%;
        /* la largeur est definie par le parent */
        height: 0;
        padding-bottom: 125%;
        /* calcul de la hauteur en fonction de la largeur du parent */
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    &--top-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 1;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &--top-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &--bottom-right {
        position: absolute;
        bottom: 8px;
        right: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 10;

        svg {
            width: 25px;
            height: 25px;
        }
    }

    &--tooltip {
        padding: 0;

        a {
            text-decoration: underline;
        }
    }

    &--checkbox-label {
        color: $darkcolor;
        font-size: 12px;

        &-disabled {
            color: rgba(0, 0, 0, .25);
        }
    }
}

.ant-tooltip .ant-tooltip-inner {
    background-color: #ebebed;
    color: $darkcolor;
    font-weight: normal;
    font-size: 12px;
    text-align: center;
}

.ant-tooltip-arrow {
    color: #ebebed;
    --antd-arrow-background-color: #ebebed;
}