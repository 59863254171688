.card-cart {
    $el: &;

    &--container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        min-height: 180px;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
        box-sizing: border-box;
        padding: 16px;
        gap: 16px;
        margin-bottom: 16px;
        overflow: hidden;

        .ant-card-cover {
            flex: 1;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            position: relative;
            padding: 0;

            @include breakpoints(mdl) {
                margin-bottom: 0;
            }
        }

        .ant-card-body {
            flex: 5;
            width: 100%;

            @include ratio_breakpoints(one) {
                flex: 3;
            }

            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 0;
            padding: 0;
        }

        &-swipe {
            background-color: white;

            @include ratio_breakpoints(one) {
                border: none;
            }
        }

    }

    &--image {
        width: 100%;
        max-width: 155px;

        &>img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &--flex {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &--size {
        flex: 1;
        align-items: center;
        display: flex;
        padding-top: 8px;
    }

    &--favorite-button {
        position: absolute;
        top: 0;
        right: 0;

        .ant-card-cover &{
            width: auto;
        }

    }

    &--subname {
        color: gray;
    }
}