.swipe {
    &--carousel {
        position: absolute;
        height: auto;
        z-index: 1;
        left: 0;
        right: 0;

        &-top{
            top: 40vw;
        }
        &-bottom {
            top: 66vw;
        }
    }

    &--center {
        border-bottom: 1px solid #f7f7fa;
        justify-content: space-between;
        @include ratio_breakpoints(one) {
            display: none
        }
    }

    &--right {
        display: none;
        justify-content: flex-end;
        @include ratio_breakpoints(one) {
            border-bottom: 1px solid #f7f7fa;
            display: flex
        }
    }
}