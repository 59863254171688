.error {
    &--container {
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    &--content-container {
        width: 50%;
        text-align: center;
    }

    &--title {
        margin-bottom: 16px;
        font-weight: bold;
        font-size: 24px;

        @include ratio_breakpoints(one) {
            font-size: 32px;
          }
    }
}