.collapse {
    $el: &;

    width: 100%;

    &--container {
        width: 100% !important;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
        font-weight: 500;
        overflow: hidden;
    }

    &--checkbox {
        width: 100%;
    }

    &--bubble-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0 10px;
    }

    &--bubble {
        background: #ffffff;
        box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
        border-radius: 24px;
        width: auto;
        padding: 6px 25px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.2px;

        color: #1c1c28;
    }

    &--checkbox-container {
        display: flex;
        flex-direction: column;

        .ant-checkbox-wrapper {
            margin-left: 8px;
        }

        .ant-collapse-content-box {
            display: flex;
            flex-direction: column;
            padding-top: 0px !important;
        }
    }
}