*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
    overscroll-behavior-y: none;
}

body {
    box-sizing: border-box;
    margin: 0;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-family: $font-main;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

p {
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    $primary-antd: var(--primary-color);
    background-color: white;
}

::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}

#root,
.App {
    height: 100%;
    width: 100%;
    display: flex;

    &--loading {
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}

%custom-scrollbar {
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 6px;
        margin-left: 6px;
        background-color: #f2f2f2;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-clip: content-box;
        background-color: var(--scrollbar-color);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
}

.show-mobile {
    @include ratio_breakpoints(one) {
        display: none !important;
    }
}

.show-desktop {
    @include mx_ratio_breakpoints(one) {
        display: none !important;
    }
}

.ant-btn-primary {
    font-weight: 600;
}

.ant-btn>div {
    display: none;
}

.ant-checkbox {
    border: none;
}

.flex-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

/* Prevent lazyload from showing borders before loading */
img {
    opacity: 0;
}

img[src] {
    opacity: 1;
}

.plusminus-icon {
    circle {
        fill-opacity: 0.7;
    }
}

.anticon.anticon-zoom-in > svg {
    width: 20px;
    height: 20px;
}

.ant-modal {
    max-width: 100vw !important;
    margin: 0 !important;
}

.ant-checkbox-inner {
    border: 1px solid var(--primary-color) !important;
}

#referenceElement {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: -99999;
}