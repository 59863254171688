.menu {
    $el: &;

    &--container {
        // Workaround so Antd doesn't detect an overflow
        flex-grow: 0.01;

        .ant-tabs-nav-wrap::before {
            box-shadow: inset 8px 0px 4px -4px rgb(0 0 0 / 18%) !important;
        }
        .ant-tabs-nav-wrap::after {
            box-shadow: inset -8px 0px 4px -4px rgb(0 0 0 / 18%) !important;
        }

        .ant-tabs-tab {
            padding: 16px 0;

            &:first-child{
                margin-left: 16px;
            }

            &:nth-last-child(-n+2){
                margin-right: 16px;
            }
        }
    }

    &--filter {
        .ant-tabs-nav-wrap {
            border-bottom: solid 1px lightgrey;
        }
    }

    &--sub-container {
        @extend .menu--container;

        padding: 0;
        min-width: 0;
        width: 100%;
        display: flex;

        .ant-tabs-nav{
            margin: 0 !important;
            flex: 1;
        }

        .ant-tabs {
            width: 100%;
        }

        .ant-tabs-tab {
            padding: 8px 0;
            font-size: 12px;
        }

        .ant-tabs-content-holder {
            display: none;
        }
    }
}

.ant-menu-horizontal {
    border-bottom: none !important;
}