.model {
    &--container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1;
        width: 100%;
        overflow: hidden;
        background-color: white;
        transition: opacity 0.2s 0.2s;
    }

    &--title {
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        width: 100%;
        padding: 0 40px;
    }

    &--title-count {
        margin-left: 8px;
    }

    &--content {
        display: flex;
        width: 100%;
        flex: 1;
        justify-content: center;
        align-items: center;
        overflow-y: auto;
        padding: 0;
    }

    &--header {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    &--empty {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
    }

    &--carousel-container {
        min-width: 100%;
        height: calc(100% - 8px);

        @media screen and (min-height: 450px) {
            height: 100%;
        }

        @include ratio_breakpoints(one) {
            width: calc(100vw - 32px);
        }

        .slick-slider, .slick-list, .slick-track {
            height: 100%;
        }

        .slick-slide>div {
            height: 100%;
            width: 100%;
        }

        &--with-dots .slick-slider {
            height: calc(100% - 26px);
        }

        .slick-track {
            display: flex;
            column-gap: 8px;
            padding-bottom: 20px;
        }

        .slick-initialized .slick-slide {
            display: flex;
            justify-content: center;
        }
    }

    &--tile-carousel--container {
        height: 100%;
        min-width: 100%;

        .slick-slider, .slick-list, .slick-track {
            height: 100%;
        }

        .slick-slide {
            &>div {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }
}