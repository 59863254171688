.modal {

    padding: 0;

    @media only screen and (max-width: 768px) {
        top: 0;
        margin: 0;
    }

    &--carousel-container {
        padding: 0;

        @include ratio_breakpoints(one) {
            padding: 0 16px;
        }

        .slick-list {
            display: flex;
        }

        .slick-slide {
            padding: 0 8px;
        }

        .slick-track {
            display: flex;
            align-items: flex-start;
            flex: 1;
            justify-content: center;
        }

        // Handle Size of slides
        .slick-slide {
            opacity: 1;

            &>div {
                display: flex;
                height: 100%;
                justify-content: center;
            }
        }

        flex: 1;
        width: 100%;
        margin-top: 16px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &--model {
        top: 0;
        width: 100vw !important;
        max-width: unset;
        height: 100%;
        position: relative !important;

        @include ratio_breakpoints(one) {
            width: auto !important;
            height: calc(100% - 32px);
        }

        .ant-modal,
        .ant-modal-content {
            margin: 0;
            padding: 0;
            top: 0;
            background-color: transparent;
            height: 100%;
        }

        .ant-modal-body {
            display: flex;
            padding: 0px;
            max-height: 100vh;
            height: 100%;
        }

        .ant-modal-title {
            text-align: center;
        }

        .ant-modal-content .ant-modal-close {
            top: 8px;
            inset-inline-end: 8px;
        }

        .ant-modal-content {
            box-shadow: none;
        }
    }

    &--model--root {
        &.ant-modal-root .ant-modal-mask {
            height: 200%;
        }

        .ant-modal-wrap {
            overflow: unset;
        }
    }

    &--favorites {
        width: 100% !important;
        height: calc(100% - 32px);

        @include ratio_breakpoints(one) {
            width: 80% !important;
        }

        .ant-modal-content {
            height: 100%;
            padding: 0;
        }

        .ant-modal-body {
            height: 100%;
            margin-right: 0;
        }
    }

    &--model-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1;
        width: 100%;
        overflow: hidden;
    }

    &--product-container {
        max-width: 100%;
        height: 100%;

        @include ratio_breakpoints(one) {
            max-width: unset;
            height: calc(100% - 32px);
        }

        .ant-modal-content {
            height: 100%;
            padding: 0;
        }

        .ant-modal-body {
            height: 100%;
        }
    }

    &--container {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-x: hidden;
        max-width: 100%;
        margin: 0;

        .ant-modal-warp {
            overflow: unset;
        }

        .ant-modal-content {
            height: 100%;
            padding: 0;
            overflow-y: auto;
        }

        @include ratio_breakpoints(one) {
            & &:not(.modal--no-recommendation) {
                height: calc(100vh - 48px);
            }
        }
    }

    &--filter-container {
        @extend .modal--container;

        height: 100vh;
    }

    &--top-container {
        padding: 0 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &--no-recommendation {
        height: auto;
    }

    &--title {
        padding-bottom: 4px;
        padding-left: 8px;
        margin-top: 16px;
        border-bottom: 1px lightgrey solid;
    }

    &--button-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 16px;
    }

    &--button {
        flex: 1;
        min-width: 180px;
    }

    &--image-container {
        margin-bottom: 16px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    &--close {
        position: absolute;
        right: 8px;
        color: black;
        z-index: 1;

        @media (hover: hover) {
            &:hover {
                color: black !important;
            }
        }
    }
}