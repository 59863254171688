.carousel {
    &--container {
        width: 100%;
        height: 100%;
        flex: 1;
        display: flex !important;
        align-items: center;
        overflow-y: auto;

        .slick-list,
        .slick-track {
            width: 100%;
            height: 100%;
        }

        .slick-track {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 34px;
            box-sizing: border-box;
        }

        .slick-slide {
            &:focus {
                box-shadow: none !important;
            }
            
            &>div {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }

            transition: none;
        }

        .slick-slide {
            height: 100%;
        }

        .slick-dots {
            bottom: 0px;
            margin-bottom: 8px;
        }
    }

    &--container-no-swipe {
        .slick-track {
            padding-bottom: 8px;
            height: 100%;
        }
    }

    &--arrow {
        cursor: pointer;
        position: absolute;
        display: flex;
        height: 2rem !important;
        width: 2rem !important;
        background: $background !important;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);

        svg {
            font-size: 13px;
            color: var(--primary-color);
        }

        &:before {
            display: none;
        }
    }

    &--left {
        left: 4px !important;
        z-index: 999;

        @include ratio_breakpoints(one) {
            left: 5px !important;
        }
    }

    &--right {
        right: 4px !important;
        z-index: 999;

        @include ratio_breakpoints(one) {
            right: 5px !important;
        }
    }

}