.tabs {
    $el: &;

    &--container {
        width: 100%;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        z-index: 999;

        @include breakpoints(mdl) {
            display: none;
        }
    }

    &--mobile {
        @include ratio_breakpoints(one) {
            display: none;
        }
    }

    &--sticky {
        z-index: 9999;

        &>div {
            z-index: 9999;
        }
    }

    &--experience {
        height: 34px;
        min-width: 80px;
        width: 100%;
        @include ratio_breakpoints(one) {
            height: 40px;
        }

        .ant-tabs {
            height: 40px;
        }

        .ant-tabs-tab {
            padding: 0 8px;
            height: 100%;

            &:first-child {
                margin-left: 16px;
            }

            &:nth-last-child(-n+2) {
                margin-right: 16px;
            }
        }

        .ant-tabs-tab:hover {
            color: black;
            border-bottom: 1px solid;
        }

        .ant-tabs-tab-active {
            pointer-events: none;
        }

        .ant-tabs-tab-active > .ant-tabs-tab-btn {
            color: black;
            font-weight: bold;
            text-shadow: none !important;
        }

        .ant-tabs-content-holder {
            display: none;
        }

        // Workaround so Antd doesn't detect an overflow
        flex-grow: 0.01;

        .ant-tabs-nav {
            margin: 0 !important;
            height: 40px;
            flex: 1;
        }

        .ant-tabs-nav-list {
            align-items: center;
        }

        .ant-tabs-nav-wrap::before {
            box-shadow: inset 8px 0px 4px -4px rgb(0 0 0 / 18%) !important;
        }

        .ant-tabs-nav-wrap::after {
            box-shadow: inset -8px 0px 4px -4px rgb(0 0 0 / 18%) !important;
        }

        .ant-tabs-nav-more {
            padding: 0 16px !important;
        }

        // ---- Hide the bar under the active tab ----
        .ant-tabs-ink-bar {
            background: black;
        }
    }
}