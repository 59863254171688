.pageheader {
  $el: &;

  &--responsive {
    display: none;
    background-color: #fff;
    @include ratio_breakpoints(one) {
      display: initial;
    }
  }

  &--paper {
    @extend %paper-base;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 99;


    @include breakpoints(lg) {
      padding: 0 50px;
    }
  }

  &--paper-left {
    @extend %paper-base;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding: 0 20px;
    border-right: 1px solid #f7f7fa;
    border-bottom: 1px solid #f7f7fa;
    z-index: 99;

    .title--h1 {
      cursor: pointer;

      &-christmas {
        font-size: 18px !important;
        @include breakpoints(smd) {
          font-size: 24px !important;
        }
      }
    }
  }

  .title--h1 {
    text-overflow: ellipsis;
    overflow: hidden;
    height: 100%;
  }

  &--container {
    padding-top: 0 !important;
    padding: 0;
  }

  &--row {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--btw {
    justify-content: space-between;
  }

  &--left {
    border-bottom: 1px solid #f7f7fa;
    display: flex;
    @include ratio_breakpoints(one) {
      display: none;
    }
  }

  &--right {
    display: none;
    @include ratio_breakpoints(one) {
      display: flex;
      // justify-content: flex-end;
      .ant-tabs-top {
        display: none;
      }
    }
    @include breakpoints(mdl) {
      .ant-tabs-top {
        display: flex;
      }
    }
    border-bottom: 1px solid #f7f7fa;
  }

  &--swipe {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

}

%paper-base {
  height: 65px;
  background: $background;
}
